/* You can add global styles to this file, and also import other style files */
@import "../node_modules/@angular/material/prebuilt-themes/indigo-pink.css";
@import "~@angular/cdk/overlay-prebuilt.css";
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@700&display=swap");
:root {
  --search-bar-default-width: 500px;
}

.btn {
  width: 173px;
  height: 50px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
}
.btn.primary {
  color: #FFFFFF;
  background-color: #26684d;
  border: none;
}
.btn.primary:focus {
  outline: none;
}
.btn.primary-large {
  color: #26684d;
  background-color: #FFFFFF;
  border: 1px solid #26684d;
  display: inline-block;
  width: auto;
}
.btn.primary-large:focus {
  outline: none;
}
.btn.secondary {
  color: #26684d;
  background-color: #FFFFFF;
  border: 1px solid #26684d;
}
.btn.secondary:focus {
  outline: none;
}
.btn:disabled {
  color: #66666659;
  background-color: #FFFFFF;
  border: 1px solid #BBBBBB;
  cursor: not-allowed;
}
.btn:disabled:focus {
  outline: none;
}

.dashboard {
  width: 56%;
  height: 48px;
  padding: 12px 8px 12px 8px;
  gap: 8px;
  border-radius: 8px;
  opacity: 0px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.08);
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
  color: #FFFFFF;
  background-color: #26684d;
  border: none;
  min-width: 130px;
}
.dashboard:focus {
  outline: none;
}

.cancel-btn {
  gap: 0px;
  opacity: 0px;
  color: #394439;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
main {
  display: block;
}

audio,
canvas,
video {
  display: inline-block;
  *display: inline;
  *zoom: 1;
}

audio:not([controls]) {
  display: none;
}

* {
  margin: 0;
  padding: 0;
  outline: none;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  font-size: "Poppins" !important;
}

*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
font,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  background: transparent;
  font-family: "Poppins" !important;
}

a,
a:hover,
a:active,
a:focus {
  outline: none;
  outline-style: none;
  outline-width: 0;
}

img {
  width: auto\9 ;
  height: auto;
  max-width: 100%;
  vertical-align: middle;
  border: 0;
  -ms-interpolation-mode: bicubic;
}

label,
select,
button,
input[type=button],
input[type=reset],
input[type=submit],
input[type=radio],
input[type=checkbox] {
  cursor: pointer;
}

input[type=search] {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  -webkit-appearance: textfield;
}

input[type=search]::-webkit-search-decoration,
input[type=search]::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

textarea {
  overflow: auto;
  vertical-align: top;
}

a {
  text-decoration: none;
  box-shadow: none;
  transition: all 0.4s ease-in-out 0s;
}

a:hover {
  text-decoration: none;
  box-shadow: none;
  border-bottom: none;
}

a:focus {
  text-decoration: none;
  box-shadow: none;
  border-bottom: none;
}

h2,
h3,
h5,
h6 {
  font-family: "All Round Gothic";
  font-weight: 400 !important;
}

h1 {
  font-family: "All Round Gothic";
}

h4 {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

p {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 400 !important;
}

body {
  margin: 0;
  padding: 0px !important;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: #000;
  line-height: normal;
  overflow-x: hidden;
  background: #f4f4f4;
}

.standard-transition {
  transition: all 0.7s ease-in;
}

.container {
  width: 100%;
  padding: 0px 1.5rem;
}

.uclean-dflex {
  display: flex;
  align-items: center;
}

.uclean-column-reverse {
  display: flex;
  flex-direction: row-reverse;
}

.uclean-dflex-spacebetween {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.uclean-dflex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.w40 {
  width: 40%;
}

.w50 {
  width: 50%;
}

.w60 {
  width: 60%;
}

.mt-10 {
  margin-top: 10px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-70 {
  margin-top: 70px;
}

.mt-80, .order-flow-section {
  margin-top: 80px;
}

.btn-wrapper .btn-primary {
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0;
  text-align: left;
  padding: 5px 20px;
  background: var(--primary-color);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
  border-radius: 10px;
  border-color: transparent;
}
.btn-wrapper .btn-primary:active {
  background: var(--primary-color);
  outline: none;
  border-color: transparent;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.02);
}
.btn-wrapper .btn-danger {
  font-size: 16px;
  font-weight: 500;
  margin-left: 5px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
  border-radius: 10px;
  line-height: 28px;
}

.white-container {
  width: 100%;
  min-height: 50vh;
  background-color: white;
  border-radius: 10px 10px 10px 10px;
  border-color: #ccc;
  padding: 20px;
  overflow: hidden;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: var(--primary-color);
}

.mat-radio-button.mat-accent .mat-radio-inner-circle {
  color: var(--primary-color);
  background-color: var(--primary-color);
}

.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element {
  background-color: rgba(255, 37, 37, 0.26);
}

.multiselect-item-checkbox[_ngcontent-umy-c113] input[type=checkbox][_ngcontent-umy-c113] + div[_ngcontent-umy-c113]:before {
  box-sizing: content-box;
  content: "";
  color: #0d0e0f;
  position: absolute;
  top: 50%;
  left: 0;
  width: 14px;
  height: 14px;
  margin-top: -9px;
  border: 2px solid var(--primary-color) !important;
  text-align: center;
  transition: 0.4s;
}

:host ::ng-deep .multiselect-item-checkbox > label:before {
  background-color: #040a0e;
  border-color: #000000;
}

.multiselect-dropdown[_ngcontent-frx-c113] .dropdown-btn[_ngcontent-frx-c113] .selected-item-container[_ngcontent-frx-c113] .selected-item[_ngcontent-frx-c113] {
  border: 1px solid #000000;
  margin-right: 4px;
  margin-bottom: 4px;
  background: #000000;
  padding: 0 5px;
  color: #fff;
  border-radius: 2px;
  float: left;
}

.mat-menu-panel .mat-menu-content button:hover:before {
  content: "";
  position: absolute;
  background: #65BA0D;
  height: 80%;
  width: 10px;
  left: -4px;
  top: 10%;
  border-radius: 50%;
}

.p20 {
  padding: 20px;
}

.pt10 {
  padding-top: 10px;
}

.pt15 {
  padding-top: 15px;
}

.pt20 {
  padding-top: 20px;
}

.pl25 {
  padding-left: 25px;
}

.pl30 {
  padding-left: 30px;
}

.mt5 {
  margin-top: 5px;
}

.mt10 {
  margin-top: 10px;
}

.mt15 {
  margin-top: 15px;
}

.mt20 {
  margin-top: 20px;
}

.mr10 {
  margin-right: 10px;
}

.ml10 {
  margin-left: 10px;
}

.v-sub {
  vertical-align: sub;
}

.v-text-top {
  vertical-align: text-top;
}

.f14 {
  font-size: 14px;
}

.f16 {
  font-size: 16px;
}

.f18 {
  font-size: 18px;
}

.f20 {
  font-size: 20px;
}

.color-black {
  color: #000;
}

.color-dark-blue {
  color: #043A27;
}

.bold {
  font-weight: 700;
}

.fw-600 {
  font-weight: 600;
}

.underline {
  text-decoration: underline;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

@media (max-width: 800px) {
  .cdk-overlay-pane {
    max-width: 90vw !important;
  }

  .mat-dialog-container {
    padding: 20px 10px !important;
  }
}
/* Hide spin buttons in Chrome, Safari, Edge, and Opera */
.no-spin::-webkit-outer-spin-button,
.no-spin::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Hide spin buttons in Firefox */
.no-spin[type=number] {
  -moz-appearance: textfield;
}